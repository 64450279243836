export default {
	ACCOUNT: {
		CHECK_USER_PLAN: {
			GET: '$GET_CHECK_USER_PLAN',
			GET_SUCCESS: '$GET_CHECK_USER_PLAN_SUCCESS',
			GET_ERROR: '$GET_CHECK_USER_PLAN_ERROR',
		},
		UPDATE_USER: '$UPDATE_USER',
		UPDATE_USER_SUCCESS: '$UPDATE_USER_SUCCESS',
		UPDATE_USER_ERROR: '$UPDATE_USER_ERROR',
		GET_USER: '$GET_USER',
		GET_USER_SUCCESS: '$GET_USER_SUCCESS',
		GET_USER_ERROR: '$GET_USER_ERROR',
		PAYMENT: {
			UPDATE: '$UPDATE_PAYMENT',
			UPDATE_SUCCESS: '$UPDATE_PAYMENT_SUCCESS',
			UPDATE_ERROR: '$UPDATE_PAYMENT_ERROR',
		},
	},
	APP: {
		SET_SEARCH_STATE: '$SET_SEARCH_STATE',
		CLEAR_SEARCH_STATE: '$CLEAR_SEARCH_STATE',
		SET_CURRENT_APP: '$SET_CURRENT_APP',
		CLEAR_CURRENT_APP: '$CLEAR_CURRENT_APP',
		GET_INFO: '$GET_APP_INFO',
		GET_INFO_SUCCESS: '$GET_APP_INFO_SUCCESS',
		GET_INFO_ERROR: '$GET_APP_INFO_ERROR',
		GET_MAPPINGS: '$GET_APP_MAPPINGS',
		GET_MAPPINGS_SUCCESS: '$GET_APP_MAPPINGS_SUCCESS',
		GET_MAPPINGS_ERROR: '$GET_APP_MAPPINGS_ERROR',
		CLEAR_MAPPINGS: '$CLEAR_APP_MAPPINGS',
		DELETE_APP: '$$DELETE_APP',
		DELETE_APP_SUCCESS: '$$DELETE_APP_SUCCESS',
		DELETE_APP_ERROR: '$$DELETE_APP_ERROR',
		GET_SHARE: '$GET_APP_SHARE',
		GET_SHARE_SUCCESS: '$GET_SHARE_SUCCESS',
		GET_SHARE_ERROR: '$GET_SHARE_ERROR',
		DELETE_SHARE: '$DELETE_APP_SHARE',
		DELETE_SHARE_SUCCESS: '$DELETE_SHARE_SUCCESS',
		DELETE_SHARE_ERROR: '$DELETE_SHARE_ERROR',
		GET_PLAN: '$GET_APP_PLAN',
		GET_PLAN_SUCCESS: '$GET_APP_PLAN_SUCCESS',
		GET_PLAN_ERROR: '$GET_APP_PLAN_ERROR',
		CREATE_SHARE: '$CREATE_APP_SHARE',
		CREATE_SHARE_SUCCESS: '$CREATE_APP_SHARE_SUCCESS',
		CREATE_SHARE_ERROR: '$CREATE_APP_SHARE_ERROR',
		TRANSFER_OWNERSHIP: '$TRANSFER_OWNERSHIP_SHARE',
		TRANSFER_OWNERSHIP_SUCCESS: '$TRANSFER_OWNERSHIP_SHARE_SUCCESS',
		TRANSFER_OWNERSHIP_ERROR: '$TRANSFER_OWNERSHIP_SHARE_ERROR',
		CREATE_SUBSCRIPTION: '$CREATE_APP_SUBSCRIPTION',
		CREATE_SUBSCRIPTION_SUCCESS: '$CREATE_APP_SUBSCRIPTION_SUCCESS',
		CREATE_SUBSCRIPTION_ERROR: '$CREATE_APP_SUBSCRIPTION_ERROR',
		DELETE_SUBSCRIPTION: '$DELETE_APP_SUBSCRIPTION',
		DELETE_SUBSCRIPTION_SUCCESS: '$DELETE_APP_SUBSCRIPTION_SUCCESS',
		DELETE_SUBSCRIPTION_ERROR: '$DELETE_APP_SUBSCRIPTION_ERROR',
		GET_METRICS: '$GET_APP_METRICS',
		GET_METRICS_SUCCESS: '$GET_APP_METRICS_SUCCESS',
		GET_METRICS_ERROR: '$GET_APP_METRICS_ERROR',
		GET_BUILD_INFO: '$GET_APP_BUILD_INFO',
		GET_BUILD_INFO_SUCCESS: '$GET_APP_BUILD_INFO_SUCCESS',
		GET_BUILD_INFO_ERROR: '$GET_APP_BUILD_INFO_ERROR',
		INSIGHTS_SIDEBAR: '$INSIGHTS_SIDEBAR',
		USERS: {
			GET: '$GET_USERS',
			GET_SUCCESS: '$GET_USERS_SUCCESS',
			GET_ERROR: '$GET_USERS_ERROR',
			CREATE_USER: '$CREATE_USER_USERS',
			CREATE_USER_SUCCESS: '$CREATE_USER_USERS_SUCCESS',
			CREATE_USER_ERROR: '$CREATE_USER_USERS_ERROR',
			EDIT_USER: '$EDIT_USER_USERS',
			EDIT_USER_SUCCESS: '$EDIT_USER_USERS_SUCCESS',
			EDIT_USER_ERROR: '$EDIT_USER_USERS_ERROR',
			DELETE_USER: '$DELETE_USER_USERS',
			DELETE_USER_SUCCESS: '$DELETE_USER_USERS_SUCCESS',
			DELETE_USER_ERROR: '$DELETE_USER_USERS_ERROR',
		},
		ANALYTICS: {
			GET: '$GET_ANALYTICS',
			GET_SUCCESS: '$GET_ANALYTICS_SUCCESS',
			GET_ERROR: '$GET_ANALYTICS_ERROR',
			GET_SUMMARY: '$GET_ANALYTICS_SUMMARY',
			GET_SUMMARY_SUCCESS: '$GET_ANALYTICS_SUMMARY_SUCCESS',
			GET_SUMMARY_ERROR: '$GET_ANALYTICS_SUMMARY_ERROR',
			GET_LATENCY: '$GET_ANALYTICS_LATENCY',
			GET_LATENCY_SUCCESS: '$GET_ANALYTICS_LATENCY_SUCCESS',
			GET_LATENCY_ERROR: '$GET_ANALYTICS_LATENCY_ERROR',
			GET_GEO_DISTRIBUTION: '$GET_ANALYTICS_GEO_DISTRIBUTION',
			GET_GEO_DISTRIBUTION_SUCCESS: '$GET_ANALYTICS_GEO_DISTRIBUTION_SUCCESS',
			GET_GEO_DISTRIBUTION_ERROR: '$GET_ANALYTICS_GEO_DISTRIBUTION_ERROR',
			GET_REQUEST_DISTRIBUTION: '$GET_ANALYTICS_REQUEST_DISTRIBUTION',
			GET_REQUEST_DISTRIBUTION_SUCCESS: '$GET_ANALYTICS_REQUEST_DISTRIBUTION_SUCCESS',
			GET_REQUEST_DISTRIBUTION_ERROR: '$GET_ANALYTICS_REQUEST_DISTRIBUTION_ERROR',
			GET_INSIGHTS: '$GET_ANALYTICS_INSIGHTS',
			GET_INSIGHTS_SUCCESS: '$GET_ANALYTICS_INSIGHTS_SUCCESS',
			GET_INSIGHTS_ERROR: '$GET_ANALYTICS_INSIGHTS_ERROR',
			UPDATE_INSIGHTS_STATUS: '$UPDATE_INSIGHTS_STATUS',
			UPDATE_INSIGHTS_STATUS_SUCCESS: '$UPDATE_INSIGHTS_STATUS_SUCCESS',
			UPDATE_INSIGHTS_STATUS_ERROR: '$UPDATE_INSIGHTS_STATUS_ERROR',
			GET_QUERY_VOLUME: '$GET_ANALYTICS_QUERY_VOLUME',
			GET_QUERY_VOLUME_SUCCESS: '$GET_ANALYTICS_QUERY_VOLUME_SUCCESS',
			GET_QUERY_VOLUME_ERROR: '$GET_ANALYTICS_QUERY_VOLUME_ERROR',
			GET_POPULAR_SEARCHES: '$GET_ANALYTICS_POPULAR_SEARCHES',
			GET_POPULAR_SEARCHES_SUCCESS: '$GET_ANALYTICS_POPULAR_SEARCHES_SUCCESS',
			GET_POPULAR_SEARCHES_ERROR: '$GET_ANALYTICS_POPULAR_SEARCHES_ERROR',
			GET_RECENT_SEARCHES: '$GET_ANALYTICS_RECENT_SEARCHES',
			GET_RECENT_SEARCHES_SUCCESS: '$GET_ANALYTICS_RECENT_SEARCHES_SUCCESS',
			GET_RECENT_SEARCHES_ERROR: '$GET_ANALYTICS_RECENT_SEARCHES_ERROR',
			GET_RECENT_RESULTS: '$GET_ANALYTICS_RECENT_RESULTS',
			GET_RECENT_RESULTS_SUCCESS: '$GET_ANALYTICS_RECENT_RESULTS_SUCCESS',
			GET_RECENT_RESULTS_ERROR: '$GET_ANALYTICS_RECENT_RESULTS_ERROR',
		},
		PERMISSION: {
			GET: '$GET_APP_PERMISSION',
			GET_SUCCESS: '$GET_APP_PERMISSION_SUCCESS',
			GET_ERROR: '$GET_APP_PERMISSION_ERROR',
			CREATE: '$CREATE_APP_PERMISSION',
			CREATE_SUCCESS: '$CREATE_APP_PERMISSION_SUCCESS',
			CREATE_ERROR: '$CREATE_APP_PERMISSION_ERROR',
			DELETE: '$DELETE_APP_PERMISSION',
			DELETE_SUCCESS: '$DELETE_APP_PERMISSION_SUCCESS',
			DELETE_ERROR: '$DELETE_APP_PERMISSION_ERROR',
			UPDATE: '$UPDATE_APP_PERMISSION',
			UPDATE_SUCCESS: '$UPDATE_APP_PERMISSION_SUCCESS',
			UPDATE_ERROR: '$UPDATE_APP_PERMISSION_ERROR',
		},
		FUNCTIONS: {
			GET: '$GET_APP_FUNCTIONS',
			GET_SUCCESS: '$GET_APP_FUNCTIONS_SUCCESS',
			GET_ERROR: '$GET_APP_FUNCTIONS_ERROR',
			SINGLE_GET: '$SINGLE_GET_APP_FUNCTIONS',
			SINGLE_GET_SUCCESS: '$SINGLE_GET_APP_FUNCTIONS_SUCCESS',
			SINGLE_GET_ERROR: '$SINGLE_GET_APP_FUNCTIONS_ERROR',
			CREATE: '$CREATE_APP_FUNCTIONS',
			CREATE_SUCCESS: '$CREATE_APP_FUNCTIONS_SUCCESS',
			CREATE_ERROR: '$CREATE_APP_FUNCTIONS_ERROR',
			DELETE: '$DELETE_APP_FUNCTIONS',
			DELETE_SUCCESS: '$DELETE_APP_FUNCTIONS_SUCCESS',
			DELETE_ERROR: '$DELETE_APP_FUNCTIONS_ERROR',
			UPDATE: '$UPDATE_APP_FUNCTIONS',
			UPDATE_SUCCESS: '$UPDATE_APP_FUNCTIONS_SUCCESS',
			UPDATE_ERROR: '$UPDATE_APP_FUNCTIONS_ERROR',
			INVOKE: '$INVOKE_APP_FUNCTIONS',
			INVOKE_SUCCESS: '$INVOKE_APP_FUNCTIONS_SUCCESS',
			INVOKE_ERROR: '$INVOKE_APP_FUNCTIONS_ERROR',
			REORDER: '$REORDER_APP_FUNCTIONS',
			REORDER_SUCCESS: '$REORDER_APP_FUNCTIONS_SUCCESS',
			REORDER_ERROR: '$REORDER_APP_FUNCTIONS_ERROR',
		},
		RULES: {
			GET: '$GET_APP_RULES',
			GET_SUCCESS: '$GET_APP_RULES_SUCCESS',
			GET_ERROR: '$GET_APP_RULES_ERROR',
			REORDER: '$REORDER_APP_RULES',
			REORDER_SUCCESS: '$REORDER_APP_RULES_SUCCESS',
			REORDER_ERROR: '$REORDER_APP_RULES_ERROR',
			DELETE: '$DELETE_APP_RULE',
			DELETE_SUCCESS: '$DELETE_APP_RULE_SUCCESS',
			DELETE_ERROR: '$DELETE_APP_RULE_ERROR',
			TOGGLE_STATUS: '$TOGGLE_STATUS',
			TOGGLE_STATUS_SUCCESS: '$TOGGLE_STATUS_SUCCESS',
			TOGGLE_STATUS_ERROR: '$TOGGLE_STATUS_ERROR',
			CREATE: '$CREATE_RULE',
			CREATE_ERROR: '$CREATE_RULE_ERROR',
			CREATE_SUCCESS: '$CREATE_RULE_SUCCESS',
			UPDATE_RULE: '$UPDATE_RULE',
			UPDATE_RULE_SUCCESS: '$UPDATE_RULE_SUCCESS',
			UPDATE_RULE_ERROR: '$UPDATE_RULE_ERROR',
			CLONE: '$CLONE_RULE',
			CLONE_ERROR: '$CLONE_RULE_ERROR',
			CLONE_SUCCESS: '$CLONE_RULE_SUCCESS',
		},
		PIPELINES: {
			GET: '$GET_APP_PIPELINES',
			GET_SUCCESS: '$GET_APP_PIPELINES_SUCCESS',
			GET_ERROR: '$GET_APP_PIPELINES_ERROR',
			GET_PIPELINE_VERSIONS: '$GET_PIPELINE_VERSIONS',
			GET_PIPELINE_VERSIONS_SUCCESS: '$GET_PIPELINE_VERSIONS_SUCCESS',
			GET_PIPELINE_VERSIONS_ERROR: '$GET_PIPELINE_VERSIONS_ERROR',
			UPDATE_CURRENT_ACTIVE_VERSION: '$UPDATE_CURRENT_ACTIVE_VERSION',
			UPDATE_CURRENT_ACTIVE_VERSION_SUCCESS: '$UPDATE_CURRENT_ACTIVE_VERSION_SUCCESS',
			UPDATE_CURRENT_ACTIVE_VERSION_ERROR: '$UPDATE_CURRENT_ACTIVE_VERSION_ERROR',
			REORDER: '$REORDER_APP_PIPELINES',
			REORDER_SUCCESS: '$REORDER_APP_PIPELINES_SUCCESS',
			REORDER_ERROR: '$REORDER_APP_PIPELINES_ERROR',
			DELETE: '$DELETE_APP_PIPELINE',
			DELETE_SUCCESS: '$DELETE_APP_PIPELINE_SUCCESS',
			DELETE_ERROR: '$DELETE_APP_PIPELINE_ERROR',
			TOGGLE_STATUS: '$TOGGLE_PIPELINE_STATUS',
			TOGGLE_STATUS_SUCCESS: '$TOGGLE_PIPELINE_STATUS_SUCCESS',
			TOGGLE_STATUS_ERROR: '$TOGGLE_PIPELINE_STATUS_ERROR',
			CREATE: '$CREATE_PIPELINE',
			CREATE_ERROR: '$CREATE_PIPELINE_ERROR',
			CREATE_SUCCESS: '$CREATE_PIPELINE_SUCCESS',
			CREATE_VERSION: '$CREATE_PIPELINE_VERSION',
			CREATE_VERSION_ERROR: '$CREATE_PIPELINE_VERSION_ERROR',
			CREATE_VERSION_SUCCESS: '$CREATE_PIPELINE_VERSION_SUCCESS',
			UPDATE_PIPELINE: '$UPDATE_PIPELINE',
			UPDATE_PIPELINE_SUCCESS: '$UPDATE_PIPELINE_SUCCESS',
			UPDATE_PIPELINE_ERROR: '$UPDATE_PIPELINE_ERROR',
			UPDATE_PIPELINE_VERSION: '$UPDATE_PIPELINE_VERSION',
			UPDATE_PIPELINE_VERSION_SUCCESS: '$UPDATE_PIPELINE_VERSION_SUCCESS',
			UPDATE_PIPELINE_VERSION_ERROR: '$UPDATE_PIPELINE_VERSION_ERROR',
			CLONE: '$CLONE_PIPELINE',
			CLONE_ERROR: '$CLONE_PIPELINE_ERROR',
			CLONE_SUCCESS: '$CLONE_PIPELINE_SUCCESS',
			GET_SCRIPTS: '$GET_APP_PIPELINES_SCRIPTS',
			GET_SCRIPTS_SUCCESS: '$GET_APP_PIPELINES_SCRIPTS_SUCCESS',
			GET_SCRIPTS_ERROR: '$GET_APP_PIPELINES_SCRIPTS_ERROR',
			VALIDATE_PIPELINE: '$VALIDATE_APP_PIPELINE',
			VALIDATE_PIPELINE_SUCCESS: '$VALIDATE_APP_PIPELINE_SUCCESS',
			VALIDATE_PIPELINE_ERROR: '$VALIDATE_APP_PIPELINE_ERROR',
			GET_USAGE_STATS: '$GET_PIPELINES_USAGE_STATS',
			GET_USAGE_STATS_SUCCESS: '$GET_PIPELINES_USAGE_STATS_SUCCESS',
			GET_USAGE_STATS_ERROR: '$GET_PIPELINES_USAGE_STATS_ERROR',
			UPDATE_PIPELINE_VERSION_LIVE_STATUS: '$UPDATE_PIPELINE_VERSION_LIVE_STATUS',
			UPDATE_PIPELINE_VERSION_LIVE_STATUS_SUCCESS:
				'$UPDATE_PIPELINE_VERSION_LIVE_STATUS_SUCCESS',
			UPDATE_PIPELINE_VERSION_LIVE_STATUS_ERROR: '$UPDATE_PIPELINE_VERSION_ERROR',
		},
		SCRIPT_RULES: {
			GET: '$GET_APP_SCRIPT_RULE',
			GET_SUCCESS: '$GET_APP_SCRIPT_RULE_SUCCESS',
			GET_ERROR: '$GET_APP_SCRIPT_RULE_ERROR',
			VALIDATE: '$VALIDATE_APP_SCRIPT_RULE',
			VALIDATE_SUCCESS: '$VALIDATE_APP_SCRIPT_RULE_SUCCESS',
			VALIDATE_ERROR: '$VALIDATE_APP_SCRIPT_RULE_ERROR',
		},
		PRIVATE_REGISTRY: {
			GET: '$GET_PRIVATE_REGISTRY',
			GET_SUCCESS: '$GET_PRIVATE_REGISTRY_SUCCESS',
			GET_ERROR: '$GET_PRIVATE_REGISTRY_ERROR',
			UPDATE: '$UPDATE_PRIVATE_REGISTRY',
			UPDATE_SUCCESS: '$UPDATE_PRIVATE_REGISTRY_SUCCESS',
			UPDATE_ERROR: '$UPDATE_PRIVATE_REGISTRY_ERROR',
		},
		TEMPLATES: {
			GET: '$GET_APP_TEMPLATE',
			GET_SUCCESS: '$GET_APP_TEMPLATE_SUCCESS',
			GET_ERROR: '$GET_APP_TEMPLATE_ERROR',
			GET_ALL: '$GET_ALL_APP_TEMPLATE',
			GET_ALL_SUCCESS: '$GET_ALL_APP_TEMPLATE_SUCCESS',
			GET_ALL_ERROR: '$GET_ALL_APP_TEMPLATE_ERROR',
			UPDATE: '$UPDATE_APP_TEMPLATE',
			UPDATE_SUCCESS: '$UPDATE_APP_TEMPLATE_SUCCESS',
			UPDATE_ERROR: '$UPDATE_APP_TEMPLATE_ERROR',
			DELETE: '$DELETE_APP_TEMPLATE',
			DELETE_SUCCESS: '$DELETE_APP_TEMPLATE_SUCCESS',
			DELETE_ERROR: '$DELETE_APP_TEMPLATE_ERROR',
			VALIDATE: '$VALIDATE_APP_TEMPLATE',
			CLEAR_VALIDATE: '$CLEAR_VALIDATE_APP_TEMPLATE',
			VALIDATE_SUCCESS: '$VALIDATE_APP_TEMPLATE_SUCCESS',
			VALIDATE_ERROR: '$VALIDATE_APP_TEMPLATE_ERROR',
		},
		PUBLIC_KEY: {
			GET: '$GET_APP_PUBLIC_KEY',
			GET_SUCCESS: '$GET_APP_PUBLIC_KEY_SUCCESS',
			GET_ERROR: '$GET_APP_PUBLIC_KEY_ERROR',
			UPDATE: '$UPDATE_APP_PUBLIC_KEY',
			UPDATE_SUCCESS: '$UPDATE_APP_PUBLIC_KEY_SUCCESS',
			UPDATE_ERROR: '$UPDATE_APP_PUBLIC_KEY_ERROR',
		},
		FILTER: {
			GET_LABEL: '$GET_FILTER_LABEL',
			GET_LABEL_SUCCESS: 'GET_FILTER_LABEL_SUCCESS',
			GET_LABEL_ERROR: 'GET_FILTER_LABEL_ERROR',
			GET_VALUE: '$GET_FILTER_VALUE',
			GET_VALUE_SUCCESS: 'GET_FILTER_VALUE_SUCCESS',
			GET_VALUE_ERROR: 'GET_FILTER_VALUE_ERROR',
			SET_FILTER_VALUE: 'SET_FILTER_VALUE',
			INITIALIZE_FILTER_VALUE: 'INITIALIZE_FILTER_VALUE',
			CLEAR_FILTER_VALUE: 'CLEAR_FILTER_VALUE',
		},
		SUGGESTIONS: {
			GET_PREFERENCES: '$GET_SUGGESTIONS_PREFERENCES',
			GET_PREFERENCES_SUCCESS: '$GET_SUGGESTIONS_PREFERENCES_SUCCESS',
			GET_PREFERENCES_ERROR: '$GET_SUGGESTIONS_PREFERENCES_ERROR',
			SAVE_PREFERENCES: '$SAVE_SUGGESTIONS_PREFERENCES',
			SAVE_PREFERENCES_SUCCESS: '$SAVE_SUGGESTIONS_PREFERENCES_SUCCESS',
			SAVE_PREFERENCES_ERROR: '$SAVE_SUGGESTIONS_PREFERENCES_ERROR',
		},
		POPULAR_SUGGESTIONS: {
			GET_PREFERENCES: '$GET_SUGGESTIONS_PREFERENCES',
			GET_PREFERENCES_SUCCESS: '$GET_SUGGESTIONS_PREFERENCES_SUCCESS',
			GET_PREFERENCES_ERROR: '$GET_SUGGESTIONS_PREFERENCES_ERROR',
			SAVE_PREFERENCES: '$SAVE_SUGGESTIONS_PREFERENCES',
			SAVE_PREFERENCES_SUCCESS: '$SAVE_SUGGESTIONS_PREFERENCES_SUCCESS',
			SAVE_PREFERENCES_ERROR: '$SAVE_SUGGESTIONS_PREFERENCES_ERROR',
		},
		RECENT_SUGGESTIONS: {
			GET_PREFERENCES: '$GET_SUGGESTIONS_PREFERENCES',
			GET_PREFERENCES_SUCCESS: '$GET_SUGGESTIONS_PREFERENCES_SUCCESS',
			GET_PREFERENCES_ERROR: '$GET_SUGGESTIONS_PREFERENCES_ERROR',
			SAVE_PREFERENCES: '$SAVE_SUGGESTIONS_PREFERENCES',
			SAVE_PREFERENCES_SUCCESS: '$SAVE_SUGGESTIONS_PREFERENCES_SUCCESS',
			SAVE_PREFERENCES_ERROR: '$SAVE_SUGGESTIONS_PREFERENCES_ERROR',
		},
		INDEX_SUGGESTIONS: {
			GET_PREFERENCES: '$GET_SUGGESTIONS_PREFERENCES',
			GET_PREFERENCES_SUCCESS: '$GET_SUGGESTIONS_PREFERENCES_SUCCESS',
			GET_PREFERENCES_ERROR: '$GET_SUGGESTIONS_PREFERENCES_ERROR',
			SAVE_PREFERENCES: '$SAVE_SUGGESTIONS_PREFERENCES',
			SAVE_PREFERENCES_SUCCESS: '$SAVE_SUGGESTIONS_PREFERENCES_SUCCESS',
			SAVE_PREFERENCES_ERROR: '$SAVE_SUGGESTIONS_PREFERENCES_ERROR',
		},
		FEATURED_SUGGESTIONS: {
			GET: '$GET_APP_FEATURED_SUGGESTIONS',
			GET_SUCCESS: '$GET_APP_FEATURED_SUGGESTIONS_SUCCESS',
			GET_ERROR: '$GET_APP_FEATURED_SUGGESTIONS_ERROR',
			UPDATE: '$UPDATE_FEATURED_SUGGESTIONS',
			UPDATE_SUCCESS: '$UPDATE_FEATURED_SUGGESTIONS_SUCCESS',
			UPDATE_ERROR: '$UPDATE_FEATURED_SUGGESTIONS_ERROR',
			DELETE: '$DELETE_APP_FEATURED_SUGGESTIONS',
			DELETE_SUCCESS: '$DELETE_APP_FEATURED_SUGGESTIONS_SUCCESS',
			DELETE_ERROR: '$DELETE_APP_FEATURED_SUGGESTIONS_ERROR',
		},
		CACHE: {
			GET_PREFERENCES: '$GET_CACHE_PREFERENCES',
			GET_PREFERENCES_SUCCESS: '$GET_CACHE_PREFERENCES_SUCCESS',
			GET_PREFERENCES_ERROR: '$GET_CACHE_PREFERENCES_ERROR',
			SAVE_PREFERENCES: '$SAVE_CACHE_PREFERENCES',
			SAVE_PREFERENCES_SUCCESS: '$SAVE_CACHE_PREFERENCES_SUCCESS',
			SAVE_PREFERENCES_ERROR: '$SAVE_CACHE_PREFERENCES_ERROR',
			EVICT: '$EVICT_CACHE',
			EVICT_SUCCESS: '$EVICT_CACHE_SUCCESS',
			EVICT_ERROR: '$EVICT_CACHE_ERROR',
		},
		SYNC_PREFERENCES: {
			GET_PREFERENCES: '$GET_PREFERENCES',
			GET_PREFERENCES_SUCCESS: '$GET_PREFERENCES_SUCCESS',
			GET_PREFERENCES_ERROR: '$GET_PREFERENCES_ERROR',
			SAVE_PREFERENCES: '$SAVE_PREFERENCES',
			SAVE_PREFERENCES_SUCCESS: '$SAVE_PREFERENCES_SUCCESS',
			SAVE_PREFERENCES_ERROR: '$SAVE_PREFERENCES_ERROR',
		},
		SEARCH_SETTINGS: {
			GET: '$GET_SEARCH_SETTINGS',
			GET_SUCCESS: '$GET_SEARCH_SETTINGS_SUCCESS',
			GET_ERROR: '$GET_SEARCH_SETTINGS_ERROR',
			GET_DEFAULT: '$GET_DEFAULT_SEARCH_SETTINGS',
			GET_DEFAULT_SUCCESS: '$GET_DEFAULT_SEARCH_SETTINGS_SUCCESS',
			GET_DEFAULT_ERROR: '$GET_DEFAULT_SEARCH_SETTINGS_ERROR',
			UPDATE: '$UPDATE_SEARCH_SETTINGS',
			UPDATE_SUCCESS: '$UPDATE_SEARCH_SETTINGS_SUCCESS',
			UPDATE_ERROR: '$GET_SEARCH_SETTINGS_ERROR',
			DELETE: '$DELETE_SEARCH_SETTINGS',
			DELETE_SUCCESS: '$DELETE_SEARCH_SETTINGS_SUCCESS',
			DELETE_ERROR: '$DELETE_SEARCH_SETTINGS_ERROR',
			SET_LOCAL_SEARCH_RELEVANCY_STATE: '$SET_LOCAL_SEARCH_RELEVANCY_STATE',
			SET_LOCAL_MAPPING_STATE: '$SET_LOCAL_MAPPING_STATE',
			SET_ADVANCE_SEARCH_STATE: '$SET_ADVANCE_SEARCH_STATE',
			SET_RE_INDEXING_TASKS: '$SET_RE_INDEXING_TASK',
			GET_RE_INDEXING_TASKS: '$GET_RE_INDEXING_TASK',
			ADD_RE_INDEXING_TASKS: '$ADD_RE_INDEXING_TASKS',
		},
		GRADE: {
			GET: '$GET_APP_GRADE',
			GET_SUCCESS: '$GET_APP_GRADE_SUCCESS',
			GET_ERROR: '$GET_APP_GRADE_ERROR',
		},
		UI_BUILDER: {
			SEARCH_PREFERENCES: {
				GET: '$GET_SEARCH_PREFERENCES_N',
				GET_SUCCESS: '$GET_SEARCH_PREFERENCES_SUCCESS_N',
				GET_ERROR: '$GET_SEARCH_PREFERENCES_ERROR_N',
				SAVE: '$SAVE_SEARCH_PREFERENCES_N',
				SAVE_SUCCESS: '$SAVE_SEARCH_PREFERENCES_SUCCESS_N',
				SAVE_ERROR: '$SAVE_SEARCH_PREFERENCES_ERROR_N',
				DELETE: '$DELETE_SEARCH_PREFERENCES_N',
				DELETE_SUCCESS: '$DELETE_SEARCH_PREFERENCES_SUCCESS_N',
				DELETE_ERROR: '$DELETE_SEARCH_PREFERENCES_ERROR_N',
			},
			SEARCH_PREFERENCE_VERSIONS: {
				GET: '$GET_SEARCH_PREFERENCE_VERSIONS_N',
				GET_SUCCESS: '$GET_SEARCH_PREFERENCE_VERSIONS_SUCCESS_N',
				GET_ERROR: '$GET_SEARCH_PREFERENCE_VERSIONS_ERROR_N',
				DELETE: '$DELETE_SEARCH_PREFERENCE_VERSIONS_N',
				DELETE_SUCCESS: '$DELETE_SEARCH_PREFERENCE_VERSIONS_SUCCESS_N',
				DELETE_ERROR: '$DELETE_SEARCH_PREFERENCE_VERSIONS_ERROR_N',
				GET_LATEST: '$GET_LATEST_SEARCH_PREFERENCE_VERSION_N',
				GET_LATEST_SUCCESS: '$GET_LATEST_SEARCH_PREFERENCE_VERSION_SUCCESS_N',
				GET_LATEST_ERROR: '$GET_LATEST_SEARCH_PREFERENCE_VERSION_ERROR_N',
				GET_VERSION_CODE: '$GET_SEARCH_PREFERENCE_VERSION_CODE_N',
				GET_VERSION_CODE_SUCCESS: '$GET_SEARCH_PREFERENCE_VERSION_CODE_N_SUCCESS_N',
				GET_VERSION_CODE_ERROR: '$GET_SEARCH_PREFERENCE_VERSION_CODE_N_ERROR_N',
				UPDATE_PREFERENCE_STATE: '$UPDATE_SEARCH_PREFERENCE_VERSION_STATE',
				UPDATE_PREFERENCE_STATE_SUCCESS: '$UPDATE_SEARCH_PREFERENCE_VERSION_STATE_SUCCESS',
				UPDATE_PREFERENCE_STATE_ERROR: '$UPDATE_SEARCH_PREFERENCE_VERSION_STATE_ERROR',
			},
			SEARCH_PREFERENCE_DEPLOYEMENTS: {
				GET: '$GET_LATEST_SEARCH_PREFERENCE_DEPLOYEMENT_STATUS',
				GET_SUCCESS: 'GET_LATEST_SEARCH_PREFERENCE_DEPLOYEMENT_STATUS_SUCCESS_N',
				GET_ERROR: 'GET_LATEST_SEARCH_PREFERENCE_DEPLOYEMENT_STATUS_ERROR_N',
			},
			RECOMMENDATION_PREFERENCES: {
				GET: '$GET_RECOMMENDATION_PREFERENCES_N',
				GET_SUCCESS: '$GET_RECOMMENDATION_PREFERENCES_SUCCESS_N',
				GET_ERROR: '$GET_RECOMMENDATION_PREFERENCES_ERROR_N',
				SAVE: '$SAVE_RECOMMENDATION_PREFERENCES_N',
				SAVE_SUCCESS: '$SAVE_RECOMMENDATION_PREFERENCES_SUCCESS_N',
				SAVE_ERROR: '$SAVE_RECOMMENDATION_PREFERENCES_ERROR_N',
				DELETE: '$DELETE_RECOMMENDATION_PREFERENCES_N',
				DELETE_SUCCESS: '$DELETE_RECOMMENDATION_PREFERENCES_SUCCESS_N',
				DELETE_ERROR: '$DELETE_RECOMMENDATION_PREFERENCES_ERROR_N',
			},
			SEARCH_BOXES: {
				GET: '$GET_SEARCH_BOXES',
				GET_SUCCESS: '$GET_SEARCH_BOXES_SUCCESS',
				GET_ERROR: '$GET_SEARCH_BOXES_ERROR',
			},
			SEARCH_BOX: {
				GET: '$GET_SEARCH_BOX',
				GET_SUCCESS: '$GET_SEARCH_BOX_SUCCESS',
				GET_ERROR: '$GET_SEARCH_BOX_ERROR',
				CREATE: '$CREATE_SEARCH_BOX',
				CREATE_SUCCESS: '$CREATE_SEARCH_BOX_SUCCESS',
				CREATE_ERROR: '$CREATE_SEARCH_BOX_ERROR',
				UPDATE: '$UPDATE_SEARCH_BOX',
				UPDATE_SUCCESS: '$UPDATE_SEARCH_BOX_SUCCESS',
				UPDATE_ERROR: '$UPDATE_SEARCH_BOX_ERROR',
				DELETE: '$DELETE_SEARCH_BOX',
				DELETE_SUCCESS: '$DELETE_SEARCH_BOX_SUCCESS',
				DELETE_ERROR: '$DELETE_SEARCH_BOX_ERROR',
				CLONE: '$CLONE_SEARCH_BOX',
				CLONE_SUCCESS: '$CLONE_SEARCH_BOX_SUCCESS',
				CLONE_ERROR: '$CLONE_SEARCH_BOX_ERROR',
			},
			AUTH0: {
				// GET -> GET CALL
				GET_AUTH0_PREFERENCES: '$GET_AUTH0_PREFERENCES',
				GET_AUTH0_PREFERENCES_SUCCESS: '$GET_AUTH0_PREFERENCES_SUCCESS',
				GET_AUTH0_PREFERENCES_ERROR: '$GET_AUTH0_PREFERENCES_ERROR',
				// SET -> PUT CALL
				SET_AUTH0_PREFERENCES: '$SET_AUTH0_PREFERENCES',
				SET_AUTH0_PREFERENCES_SUCCESS: '$SET_AUTH0_PREFERENCES_SUCCESS',
				SET_AUTH0_PREFERENCES_ERROR: '$SET_AUTH0_PREFERENCES_ERROR',
				// GET -> GET CALL
				GET_AUTH0_CLIENT: '$GET_AUTH0_CLIENT',
				GET_AUTH0_CLIENT_SUCCESS: '$GET_AUTH0_CLIENT_SUCCESS',
				GET_AUTH0_CLIENT_ERROR: '$GET_AUTH0_CLIENT_ERROR',
				// CREATE -> POST CALL
				CREATE_AUTH0_CLIENT: '$CREATE_AUTH0_CLIENT',
				CREATE_AUTH0_CLIENT_SUCCESS: '$CREATE_AUTH0_CLIENT_SUCCESS',
				CREATE_AUTH0_CLIENT_ERROR: '$CREATE_AUTH0_CLIENT_ERROR',
				// UPDATE -> PATCH CALL
				UPDATE_AUTH0_CLIENT: '$UPDATE_AUTH0_CLIENT',
				UPDATE_AUTH0_CLIENT_SUCCESS: '$UPDATE_AUTH0_CLIENT_SUCCESS',
				UPDATE_AUTH0_CLIENT_ERROR: '$UPDATE_AUTH0_CLIENT_ERROR',
				// GET -> GET CALL
				GET_AUTH0_CLIENT_CONNECTIONS: '$GET_AUTH0_CLIENT_CONNECTIONS',
				GET_AUTH0_CLIENT_CONNECTIONS_SUCCESS: '$GET_AUTH0_CLIENT_CONNECTIONS_SUCCESS',
				GET_AUTH0_CLIENT_CONNECTIONS_ERROR: '$GET_AUTH0_CLIENT_CONNECTIONS_ERROR',
				// PUT -> PUT CALL
				SAVE_AUTH0_CLIENT_CONNECTIONS: '$SAVE_AUTH0_CLIENT_CONNECTIONS',
				SAVE_AUTH0_CLIENT_CONNECTIONS_SUCCESS: '$SAVE_AUTH0_CLIENT_CONNECTIONS_SUCCESS',
				SAVE_AUTH0_CLIENT_CONNECTIONS_ERROR: '$SAVE_AUTH0_CLIENT_CONNECTIONS_ERROR',
				// POST -> POST CALL
				CREATE_AUTH0_CLIENT_CONNECTION: '$CREATE_AUTH0_CLIENT_CONNECTION',
				CREATE_AUTH0_CLIENT_CONNECTION_SUCCESS: '$CREATE_AUTH0_CLIENT_CONNECTION_SUCCESS',
				CREATE_AUTH0_CLIENT_CONNECTION_ERROR: '$CREATE_AUTH0_CLIENT_CONNECTION_ERROR',
				// GET -> GET CALL
				GET_AUTH0_CLIENT_CONNECTION: '$GET_AUTH0_CLIENT_CONNECTION',
				GET_AUTH0_CLIENT_CONNECTION_SUCCESS: '$GET_AUTH0_CLIENT_CONNECTION_SUCCESS',
				GET_AUTH0_CLIENT_CONNECTION_ERROR: '$GET_AUTH0_CLIENT_CONNECTION_ERROR',
				// PATCH -> PATCH CALL
				UPDATE_AUTH0_CLIENT_CONNECTION: '$UPDATE_AUTH0_CLIENT_CONNECTION',
				UPDATE_AUTH0_CLIENT_CONNECTION_SUCCESS: '$UPDATE_AUTH0_CLIENT_CONNECTION_SUCCESS',
				UPDATE_AUTH0_CLIENT_CONNECTION_ERROR: '$UPDATE_AUTH0_CLIENT_CONNECTION_ERROR',
				// GET -> GET CALL
				GET_AUTH0_USERS: '$GET_AUTH0_USERS',
				GET_AUTH0_USERS_SUCCESS: '$GET_AUTH0_USERS_SUCCESS',
				GET_AUTH0_USERS_ERROR: '$GET_AUTH0_USERS_ERROR',
				// POST -> POST CALL
				CREATE_AUTH0_USER: '$CREATE_AUTH0_USER',
				CREATE_AUTH0_USER_SUCCESS: '$CREATE_AUTH0_USER_SUCCESS',
				CREATE_AUTH0_USER_ERROR: '$CREATE_AUTH0_USER_ERROR',
				// PATCH -> PATCH CALL
				UPDATE_AUTH0_USER_SETTINGS: '$UPDATE_AUTH0_USER_SETTINGS',
				UPDATE_AUTH0_USER_SETTINGS_SUCCESS: '$UPDATE_AUTH0_USER_SETTINGS_SUCCESS',
				UPDATE_AUTH0_USER_SETTINGS_ERROR: '$UPDATE_AUTH0_USER_SETTINGS_ERROR',
				// PATCH -> PATCH CALL
				DELETE_AUTH0_USER: '$DELETE_AUTH0_USER',
				DELETE_AUTH0_USER_SUCCESS: '$DELETE_AUTH0_USER_SUCCESS',
				DELETE_AUTH0_USER_ERROR: '$DELETE_AUTH0_USER_ERROR',
			},
		},
		STORED_QUERIES: {
			GET: '$GET_APP_STORED_QUERY',
			GET_SUCCESS: '$GET_APP_STORED_QUERY_SUCCESS',
			GET_ERROR: '$GET_APP_STORED_QUERY_ERROR',
			GET_ALL: '$GET_ALL_APP_STORED_QUERIES',
			GET_ALL_SUCCESS: '$GET_ALL_APP_STORED_QUERIES_SUCCESS',
			GET_ALL_ERROR: '$GET_ALL_APP_STORED_QUERIES_ERROR',
			UPDATE: '$UPDATE_APP_STORED_QUERY',
			UPDATE_SUCCESS: '$UPDATE_APP_STORED_QUERY_SUCCESS',
			UPDATE_ERROR: '$UPDATE_APP_STORED_QUERY_ERROR',
			DELETE: '$DELETE_STORED_QUERY_TEMPLATE',
			DELETE_SUCCESS: '$DELETE_APP_STORED_QUERY_SUCCESS',
			DELETE_ERROR: '$DELETE_APP_STORED_QUERY_ERROR',
			VALIDATE: '$VALIDATE_APP_STORED_QUERY',
			CLEAR_VALIDATE: '$CLEAR_VALIDATE_APP_STORED_QUERY',
			VALIDATE_SUCCESS: '$VALIDATE_APP_STORED_QUERY_SUCCESS',
			VALIDATE_ERROR: '$VALIDATE_APP_STORED_QUERY_ERROR',
			EXECUTE: '$EXECUTE_APP_STORED_QUERY',
			CLEAR_EXECUTE: '$CLEAR_EXECUTE_APP_STORED_QUERY',
			EXECUTE_SUCCESS: '$EXECUTE_APP_STORED_QUERY_SUCCESS',
			EXECUTE_ERROR: '$EXECUTE_APP_STORED_QUERY_ERROR',
			GET_USAGE: '$GET_USAGE_STATS',
			GET_USAGE_SUCCESS: '$GET_USAGE_STATS_SUCCESS',
			GET_USAGE_ERROR: '$GET_USAGE_STATS_ERROR',
		},
		USAGE_STATS: {
			GET: '$GET_USAGE_STATS',
			GET_SUCCESS: '$GET_USAGE_STATS_SUCCESS',
			GET_ERROR: '$GET_USAGE_STATS_ERROR',
		},
		AI: {
			GET_AI_FAQS: '$GET_AI_FAQS',
			GET_AI_FAQS_SUCCESS: '$GET_AI_FAQS_SUCCESS',
			GET_AI_FAQS_ERROR: '$GET_AI_FAQS_ERROR',
			CREATE_AI_FAQS: '$CREATE_AI_FAQS',
			CREATE_AI_FAQS_SUCCESS: '$CREATE_AI_FAQS_SUCCESS',
			CREATE_AI_FAQS_ERROR: '$CREATE_AI_FAQS_ERROR',
			UPDATE_AI_FAQS: '$UPDATE_AI_FAQS',
			UPDATE_AI_FAQS_SUCCESS: '$UPDATE_AI_FAQS_SUCCESS',
			UPDATE_AI_FAQS_ERROR: '$UPDATE_AI_FAQS_ERROR',
			DELETE_AI_FAQS: '$DELETE_AI_FAQS',
			DELETE_AI_FAQS_SUCCESS: '$DELETE_AI_FAQS_SUCCESS',
			DELETE_AI_FAQS_ERROR: '$DELETE_AI_FAQS_ERROR',
		},
	},
	MONITORING: {
		SET_CONFIG: 'SET_CONFIG',
		SET_TIME_FILTER: 'SET_TIME_FILTER',
	},
	BILLING: {
		GET_DATA_USAGE: 'GET_DATA_USAGE',
		GET_DATA_USAGE_SUCCESS: 'GET_DATA_USAGE_SUCCESS',
		GET_DATA_USAGE_ERROR: 'GET_DATA_USAGE_ERROR',
	},
	HEALTH: {
		SET_SERVER_HEALTH: 'SET_SERVER_HEALTH',
		SET_SERVER_HEALTH_SUCCESS: 'SET_SERVER_HEALTH_SUCCESS',
		SET_SERVER_HEALTH_ERROR: 'SET_SERVER_HEALTH_ERROR',
		SET_SEARCH_ENGINE_HEALTH: 'SET_SEARCH_ENGINE_HEALTH',
		SET_SEARCH_ENGINE_HEALTH_SUCCESS: 'SET_SEARCH_ENGINE_HEALTH_SUCCESS',
		SET_SEARCH_ENGINE_HEALTH_ERROR: 'SET_SEARCH_ENGINE_HEALTH_ERROR',
	},
};
